import { useEffect, useState } from "react";

/**
 * Returns the height and width of the window in pixels
 */
const useWindowDimensions = (): {
  windowHeight: number;
  windowWidth: number;
} => {
  const calculateWindowDimensions = () =>
    typeof window === "undefined"
      ? {
          windowHeight: 0,
          windowWidth: 0,
        }
      : { windowHeight: window.innerHeight, windowWidth: window.innerWidth };

  const [windowDimensions, setWindowDimensions] = useState({
    windowHeight: 0,
    windowWidth: 0,
  });

  // Have to put this in a use effect because of weirdness with SSG. Learn more
  // from this blog:
  // https://www.joshwcomeau.com/react/the-perils-of-rehydration/
  useEffect(() => {
    setWindowDimensions(calculateWindowDimensions());
    window.addEventListener("resize", () =>
      setWindowDimensions(calculateWindowDimensions())
    );
  }, []);

  return windowDimensions;
};
export default useWindowDimensions;
