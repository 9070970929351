import { createReactRouter, createRouteConfig } from "@tanstack/react-router";
import { contactPageRoute } from "./routes/contact";
import { galleryRoute } from "./routes/gallery";
import { homePageRoute } from "./routes/home";
import { pageRoute } from "./routes/page";
import rootRoute from "./routes/root";

const routeConfig = rootRoute.addChildren([
  homePageRoute,
  pageRoute,
  galleryRoute,
  contactPageRoute,
]);

export const router = createReactRouter({
  routeConfig,
});

declare module "@tanstack/react-router" {
  interface RegisterRouter {
    router: typeof router;
  }
}
