import { useEffect, useState } from "react";
import { Facebook, Instagram, Menu, Twitter, Youtube, X } from "react-feather";
import colorWithAlpha from "../lib/color-with-alpha";
import useWindowDimensions from "../lib/use-window-dimensions";
import CSS from "csstype";
import px from "../lib/px";
import { Link, useRoute } from "@tanstack/react-router";
import { NavigationEntityResponse } from "@thoburn-woodworking/strapi/models/types";
import strapi from "../lib/strapi";
import { useNavigationQuery } from "../hooks/navigation";
import logo from "../images/thoburn-woodworking-logo.png";
const menuTransitionDuration = "200ms";
const menuTransitionTimingFunction = "linear";
export const NAVBAR_MOBILE_BREAKPOINT = 768;
// The height of the menu on mobile breakpoints
const MENU_HEIGHT = 84;
// The width of the menu on desktop breakpoints
const MENU_WIDTH = 142;
// The space around the outside of the menu on desktop breakpoints
const MENU_MARGIN = 24;
// The space between items and the horizontal edges of the screen on mobile breakpoints
const MENU_PADDING = 24;
// The size of the menu icon
const ICON_SIZE = 32;

const outerNavbarStyle = ({
  isMobile,
}: {
  isMobile: boolean;
}): CSS.Properties => ({
  zIndex: 1,
  height: isMobile ? "auto" : "100vh",
  width: "100%",
  padding: isMobile ? 0 : `${MENU_MARGIN}px`,
  boxSizing: "border-box",
  flex: isMobile
    ? `0 0 ${MENU_HEIGHT}px`
    : `0 0 ${MENU_WIDTH + MENU_MARGIN * 2}px`,
  position: "sticky",
  top: 0,
});

const navBarStyle = ({
  isMobile,
  menuOpen,
}: {
  isMobile: boolean;
  menuOpen: boolean;
}): CSS.Properties => ({
  padding: px(MENU_PADDING),
  height: "100%",
  width: "100%",
  position: "relative",
  backgroundColor: menuOpen ? "transparent" : colorWithAlpha("#ffffff", 0.68),
  backdropFilter: "blur(1px)",
  WebkitBackdropFilter: "blur(1px)",
  borderRadius: isMobile ? 0 : px(24),
  boxSizing: "border-box",
  display: "flex",
  flexDirection: isMobile ? "row" : "column",
  alignItems: "center",
  justifyContent: isMobile ? "flex-end" : "center",
  transition: `background-color ${menuTransitionDuration} ${menuTransitionTimingFunction}`,
});

const menuIconStyle = ({ isMobile }: { isMobile: boolean }): CSS.Properties => {
  const iconOffset = ((isMobile ? MENU_HEIGHT : MENU_WIDTH) - ICON_SIZE) / 2;
  return {
    position: "absolute",
    top: px(iconOffset),
    left: px(isMobile ? MENU_PADDING : iconOffset),
    cursor: "pointer",
  };
};

const navBarIconsStyle = ({
  isMobile,
}: {
  isMobile: boolean;
}): CSS.Properties => ({
  display: "flex",
  flexDirection: isMobile ? "row" : "column",
  gap: px(24),
});

const navStyle = ({ menuOpen }: { menuOpen: boolean }): CSS.Properties => ({
  position: "fixed",
  zIndex: -1,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: colorWithAlpha("#ffffff", 0.68),
  backdropFilter: "blur(8px)",
  WebkitBackdropFilter: "blur(8px)",
  opacity: menuOpen ? 1 : 0,
  pointerEvents: menuOpen ? "auto" : "none",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: px(40),
  transition: `opacity ${menuTransitionDuration} ${menuTransitionTimingFunction}`,
});

const navLinkStyle = (): CSS.Properties => ({
  cursor: "pointer",
  textUnderlineOffset: px(4),
  textDecorationThickness: px(2),
});

const Navbar = (): JSX.Element => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { windowWidth } = useWindowDimensions();
  const isMobile = windowWidth < NAVBAR_MOBILE_BREAKPOINT;
  const navigationQuery = useNavigationQuery();

  const paths =
    navigationQuery.data?.attributes?.navigation_items?.map((item) => ({
      link: item!.link,
      title: item!.title,
    })) || [];

  useEffect(() => {
    // prevent body scroll
    document.querySelector("body")!.style.overflow = menuOpen
      ? "hidden"
      : "auto";
  }, [menuOpen]);

  return (
    <span style={outerNavbarStyle({ isMobile })}>
      <div style={navBarStyle({ isMobile, menuOpen })}>
        {menuOpen ? (
          <X
            onClick={() => setMenuOpen(false)}
            size={ICON_SIZE}
            color="black"
            style={menuIconStyle({ isMobile })}
          />
        ) : (
          <Menu
            onClick={() => setMenuOpen(true)}
            size={ICON_SIZE}
            color="black"
            style={menuIconStyle({ isMobile })}
          />
        )}
        <div style={navBarIconsStyle({ isMobile })}>
          <Facebook size={32} color="black" fill="black" strokeWidth={0} />
          <Twitter size={32} color="black" fill="black" strokeWidth={0} />
          <Youtube size={32} color="black" />
          <Instagram size={32} color="black" />
        </div>
      </div>
      <nav style={navStyle({ menuOpen })}>
        {!isMobile && (
          <img
            src={logo}
            alt="Thoburn Woodworking"
            style={{
              position: "absolute",
              top: 32,
              right: 32,
              cursor: "pointer",
              height: 128,
            }}
          />
        )}
        {paths.map(({ link, title }, index) => (
          <Link
            params={{}}
            search={{}}
            to={link}
            key={index}
            activeProps={{
              className: "underline",
            }}
            style={navLinkStyle()}
          >
            <h5>{title}</h5>
          </Link>
        ))}
      </nav>
    </span>
  );
};

export default Navbar;
