import axios from "axios";

if (!process.env.REACT_APP_STRAPI_API_KEY) {
  throw new Error("Missing environment variable REACT_APP_STRAPI_API_KEY");
}

if (!process.env.REACT_APP_STRAPI_HOST) {
  throw new Error("Missing environment variable REACT_APP_STRAPI_HOST");
}

const strapi = axios.create({
  baseURL: `${process.env.REACT_APP_STRAPI_HOST}/api`,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
  },
});

export default strapi;
