import { rootRouteId, useMatch } from "@tanstack/react-router";
import {
  ContactPageEntity,
  ContactPageEntityResponse,
} from "@thoburn-woodworking/strapi/models/types";
import { useState } from "react";
import Alert from "../components/Alert";
import Container from "../components/Container";
import handleFormSubmit, {
  FormSubmissionResult,
} from "../lib/handle-form-submit";
import strapi from "../lib/strapi";
import globalStyles from "../lib/styles/global";
import transformMarkdownToHtml from "../lib/transform-markdown-to-html";
import rootRoute from "./root";

type ContactPageData = {
  data: ContactPageEntity;
  bodyHtml: string | undefined;
};

export type ContactFormSubmissionResponse = {
  message: string;
  status: "success" | "error";
};

const getContactPage = async (): Promise<ContactPageData> => {
  const {
    data: { data },
  } = await strapi.get<ContactPageEntityResponse>("/contact-page", {
    params: {
      populate: "*",
    },
  });

  if (!data) {
    throw new Error("No contact page found");
  }

  let bodyHtml: string | undefined = undefined;
  if (data.attributes?.body) {
    bodyHtml = await transformMarkdownToHtml(data.attributes.body);
  }

  return { data, bodyHtml };
};

const submitContactForm = async ({
  firstName,
  lastName,
  email,
  message,
  event,
}: {
  firstName: string;
  lastName: string;
  email: string;
  message: string;
  event: React.FormEvent<HTMLFormElement>;
}): Promise<{ message: string; status: "success" | "error" }> => {
  event.preventDefault();
  if (!email) {
    return { message: "Email is required.", status: "error" };
  }
  if (!message) {
    return { message: "Message is required.", status: "error" };
  }
  try {
    await strapi.post("/ezforms/submit", {
      formData: {
        email,
        message,
        first_name: firstName,
        last_name: lastName,
      },
      formName: "contact",
    });
  } catch (error) {
    return {
      message: "Uh oh! Something went wrong, please try again later.",
      status: "error",
    };
  }
  return {
    message: "Thank you! We've been notified of your message.",
    status: "success",
  };
};

const contactPageLoader = getContactPage;

const ContactPage = () => {
  const { loaderData } = useMatch("/contact");

  const image = loaderData.data?.attributes?.image?.data?.attributes;
  const { bodyHtml } = loaderData;

  if (!image) {
    throw new Error(
      "An image must be set for the contact page in order for your site to function properly"
    );
  }

  const [formSubmissionResponse, setFormSubmissionResponse] =
    useState<ContactFormSubmissionResponse>();
  const [loading, setLoading] = useState(false);
  return (
    <Container>
      <h1 style={{ marginBottom: 56 }}>Contact</h1>
      <div
        style={{
          display: "flex",
          gap: 56,
          flexWrap: "wrap-reverse",
          alignItems: "stretch",
        }}
      >
        {image && (
          <div
            style={{
              flexBasis: 384,
              flexGrow: 1,
              minHeight: "500px",
              position: "relative",
            }}
          >
            <img
              src={image.url}
              alt={image.alternativeText ?? ""}
              style={{
                objectPosition: "center",
                objectFit: "cover",
              }}
            />
          </div>
        )}
        <div
          style={{
            gap: 24,
            display: "flex",
            flexDirection: "column",
            flexBasis: 536,
            flexGrow: 1,
          }}
        >
          {bodyHtml && <div dangerouslySetInnerHTML={{ __html: bodyHtml }} />}
          {formSubmissionResponse && (
            <Alert type={formSubmissionResponse.status}>
              {formSubmissionResponse.message}
            </Alert>
          )}
          <form
            onSubmit={async (e) => {
              setLoading(true);
              const result = await submitContactForm({
                firstName: e.currentTarget.firstName.value,
                lastName: e.currentTarget.lastName.value,
                email: e.currentTarget.email.value,
                message: e.currentTarget.message.value,
                event: e,
              });
              setFormSubmissionResponse(result);
              setLoading(false);
            }}
            action="/api/contact-form"
            method="post"
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: 24,
            }}
          >
            <input
              style={{
                flexBasis: 128,
                flexGrow: 1,
                flexShrink: 1,
              }}
              type="text"
              placeholder="First Name"
              name="firstName"
            />
            <input
              style={{
                flexBasis: 128,
                flexGrow: 1,
                flexShrink: 1,
              }}
              type="text"
              placeholder="Last Name"
              name="lastName"
            />
            <input
              style={{
                flexBasis: "100%",
                flexGrow: 1,
              }}
              type="email"
              placeholder="Email *"
              name="email"
              required
            />
            <textarea
              rows={7}
              style={{
                flexBasis: "100%",
                flexGrow: 1,
              }}
              placeholder="Message *"
              name="message"
              required
            />
            <button
              type="submit"
              style={{
                flexBasis: "100%",
                flexGrow: 1,
              }}
              disabled={loading}
            >
              {loading ? "Sending..." : "Send"}
            </button>
          </form>
        </div>
      </div>
    </Container>
  );
};

export const contactPageRoute = rootRoute.createRoute({
  path: "contact",
  component: ContactPage,
  loader: contactPageLoader,
});
