import colorWithAlpha from "../color-with-alpha";

const globalStyles = {
  colors: {
    transparent: "transparent",
    white: "#ffffff",
    black: "#000000",
    gray50: "#f5f5f5",
    gray100: "#e8e8e8",
    gray200: "#d2d2d2",
    gray300: "#bbbbbb",
    gray400: "#a4a4a4",
    gray500: "#8d8d8d",
    gray600: "#777777",
    gray700: "#606060",
    gray800: "#494949",
    gray900: "#323232",
    gray920: "#272727",
    gray950: "#131313",
    red500: "#ef4444",
    green500: "#10b981",
    error: {
      background: colorWithAlpha("#ef4444", 0.1),
      text: "#ef4444",
    },
    success: {
      background: colorWithAlpha("#10b981", 0.1),
      text: "#10b981",
    },
    warning: {
      background: colorWithAlpha("#f59e0b", 0.1),
      text: "#f59e0b",
    },
    info: {
      background: colorWithAlpha("#2563eb", 0.1),
      text: "#2563eb",
    },
  },

  spacing: {
    none: 0,
    xtiny: 2,
    tiny: 4,
    xsmall: 8,
    small: 12,
    medium: 16,
    large: 24,
    xlarge: 36,
    jumbo: 48,
  },

  borderRadius: {
    square: 0,
    rounded: 4,
    roundedLarge: 8,
    roundedFull: 999,
  },

  shadows: {
    text: {
      color: colorWithAlpha("#000000", 0.5),
      radius: 8,
      offsetX: 1,
      offsetY: 2,
    },
  },

  minTapTarget: 48,
  maxPageWidth: 1200,
};

export default globalStyles;
