/**
 * Takes a hex color and an opacity between 0 and 1 and outputs that hex color
 * with the opacity.
 * https://stackoverflow.com/questions/50890241/programmatically-add-opacity-to-a-color-in-typescript
 */
const colorWithAlpha = (color: string, opacity: number): string => {
  // coerce values so ti is between 0 and 1.
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
};

export default colorWithAlpha;
