import { useMatch } from "@tanstack/react-router";
import {
  PageEntity,
  PageEntityResponse,
  PageEntityResponseCollection,
} from "@thoburn-woodworking/strapi/models/types";
import Container from "../components/Container";
import strapi from "../lib/strapi";
import globalStyles from "../lib/styles/global";
import transformMarkdownToHtml from "../lib/transform-markdown-to-html";
import rootRoute from "./root";

type PageData = {
  data: PageEntity;
  bodyHtml: string | undefined;
};

type PageLoaderParams = {
  slug: string;
};

const getPage = async ({
  params: { slug },
}: {
  params: PageLoaderParams;
}): Promise<PageData> => {
  const {
    data: { data },
  } = await strapi.get<PageEntityResponseCollection>(
    `/pages?filters[slug][$eq]=${slug}`
  );

  const page = data[0];

  if (!page) {
    throw new Error("Page not found");
  }

  let bodyHtml: string | undefined = undefined;
  if (page.attributes?.body) {
    bodyHtml = await transformMarkdownToHtml(page.attributes.body);
  }

  return { data: page, bodyHtml };
};

const pageLoader = getPage;

const Page = () => {
  const { loaderData } = useMatch("/pages/$slug");
  const { bodyHtml } = loaderData;

  return (
    <Container>
      {loaderData.data.attributes?.title && (
        <h1 style={{ marginBottom: 56 }}>
          {loaderData.data.attributes?.title}
        </h1>
      )}
      {bodyHtml && <div dangerouslySetInnerHTML={{ __html: bodyHtml }} />}
    </Container>
  );
};

export const pageRoute = rootRoute.createRoute({
  path: "pages/$slug",
  component: Page,
  loader: pageLoader,
});
