import {
  NavigationEntity,
  NavigationEntityResponse,
} from "@thoburn-woodworking/strapi/models/types";
import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import strapi from "../lib/strapi";

const _getNavigation = async (): Promise<NavigationEntity> => {
  const {
    data: { data },
  } = await strapi.get<NavigationEntityResponse>("/navigation", {
    params: {
      populate: "*",
    },
  });

  if (!data) {
    throw new Error("No site navigation found");
  }

  return data;
};

export const NAVIGATION_QUERY_KEY: QueryKey = "navigation";
export const useNavigationQuery = ({
  options,
}: {
  options?: UseQueryOptions<NavigationEntity>;
} = {}) =>
  useQuery<NavigationEntity>(NAVIGATION_QUERY_KEY, _getNavigation, options);
