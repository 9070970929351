import { ReactNode } from "react";
import useWindowDimensions from "../lib/use-window-dimensions";
import Navbar, { NAVBAR_MOBILE_BREAKPOINT } from "./Navbar";

/**
 * A simple container that separates main page content from the navbar.
 */
const Container = ({ children }: { children: ReactNode }) => {
  const { windowWidth } = useWindowDimensions();
  const isMobile = windowWidth < NAVBAR_MOBILE_BREAKPOINT;

  return (
    <main
      style={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        gap: isMobile ? 24 : 0,
        minHeight: "100vh",
        height: "100%",
        boxSizing: "border-box",
      }}
    >
      <Navbar />
      <div
        style={{ padding: isMobile ? 24 : 56, paddingTop: isMobile ? 0 : 56 }}
      >
        {children}
      </div>
    </main>
  );
};

export default Container;
