import { rootRouteId, useMatch } from "@tanstack/react-router";
import {
  HomePageEntity,
  HomePageEntityResponse,
} from "@thoburn-woodworking/strapi/models/types";
import Container from "../components/Container";
import strapi from "../lib/strapi";
import globalStyles from "../lib/styles/global";
import transformMarkdownToHtml from "../lib/transform-markdown-to-html";
import rootRoute from "./root";

type HomePageData = {
  data: HomePageEntity;
  bodyHtml: string | undefined;
};

const getHomePage = async (): Promise<HomePageData> => {
  const {
    data: { data },
  } = await strapi.get<HomePageEntityResponse>("/home-page", {
    params: {
      populate: "*",
    },
  });

  if (!data) {
    throw new Error("No home page found");
  }

  let bodyHtml: string | undefined = undefined;
  if (data.attributes?.body) {
    bodyHtml = await transformMarkdownToHtml(data.attributes.body);
  }

  return { data, bodyHtml };
};

const homePageLoader = getHomePage;

const HomePage = () => {
  const { loaderData } = useMatch("/");

  const background_image =
    loaderData.data?.attributes?.background_image.data?.attributes?.url;
  const { bodyHtml } = loaderData;

  if (!background_image) {
    throw new Error(
      "A background image must be set for the homepage in order for your site to function properly"
    );
  }

  return (
    <div
      style={{
        backgroundImage: `linear-gradient(
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.2)
      ), url('${background_image}')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container>
        <div
          style={{
            color: globalStyles.colors.white,
            maxWidth: 553,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <h1>Thoburn</h1>
          <h4>Woodworking</h4>

          {bodyHtml && (
            <div
              dangerouslySetInnerHTML={{ __html: bodyHtml }}
              style={{ maxWidth: 553, marginTop: 22 }}
            />
          )}
        </div>
      </Container>
    </div>
  );
};

export const homePageRoute = rootRoute.createRoute({
  path: "/",
  component: HomePage,
  loader: homePageLoader,
});
