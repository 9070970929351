import { useMatch } from "@tanstack/react-router";
import {
  GalleryEntity,
  GalleryEntityResponse,
  GalleryEntityResponseCollection,
} from "@thoburn-woodworking/strapi/models/types";
import Container from "../components/Container";
import { NAVBAR_MOBILE_BREAKPOINT } from "../components/Navbar";
import strapi from "../lib/strapi";
import globalStyles from "../lib/styles/global";
import transformMarkdownToHtml from "../lib/transform-markdown-to-html";
import useWindowDimensions from "../lib/use-window-dimensions";
import rootRoute from "./root";

type GalleryData = {
  data: GalleryEntity;
  bodyHtml: string | undefined;
};

type GalleryLoaderParams = {
  slug: string;
};

const getGallery = async ({
  params: { slug },
}: {
  params: GalleryLoaderParams;
}): Promise<GalleryData> => {
  const {
    data: { data },
  } = await strapi.get<GalleryEntityResponseCollection>(
    `/galleries?filters[slug][$eq]=${slug}`,
    {
      params: {
        populate: "*",
      },
    }
  );

  const gallery = data[0];

  if (!gallery) {
    throw new Error("Page not found");
  }

  let bodyHtml: string | undefined = undefined;
  if (gallery.attributes?.body) {
    bodyHtml = await transformMarkdownToHtml(gallery.attributes.body);
  }

  return { data: gallery, bodyHtml };
};

type GalleryImage = {
  alt: string;
  src: string;
  height: number;
  width: number;
};

const isImage = (image: GalleryImage | null): image is GalleryImage =>
  image !== null;

const imageIsWide = (image: GalleryImage) => image.width / image.height > 1.3;

const galleryLoader = getGallery;

const Gallery = () => {
  const { loaderData } = useMatch("/galleries/$slug");
  const { bodyHtml } = loaderData;

  const { windowWidth } = useWindowDimensions();
  const isMobile = windowWidth < NAVBAR_MOBILE_BREAKPOINT;
  const images =
    loaderData.data?.attributes?.images?.data
      .map((image) => {
        const imageAttributes = image.attributes;
        return imageAttributes
          ? {
              alt: imageAttributes.alternativeText || "",
              src: imageAttributes.url,
              height: imageAttributes.height || 1,
              width: imageAttributes.width || 1,
            }
          : null;
      })
      .filter(isImage) ?? [];

  // Divide the images into rows using a sinple algorithm:
  // - Prefer two images in each row
  // - If the image is wide, it gets its own row, unless that means a thin image gets left on its own
  const imagesDivided = images.reduce(
    (acc, image) => {
      // each image gets its own row on mobile
      if (isMobile) {
        acc.push([image]);
        return acc;
      }

      if (acc[acc.length - 1].length === 2) {
        acc.push([image]);
      } else {
        if (acc[acc.length - 1][0] && imageIsWide(acc[acc.length - 1][0])) {
          acc.push([image]);
        } else {
          acc[acc.length - 1].push(image);
        }
      }
      return acc;
    },
    [[]] as Array<Array<typeof images[0]>>
  );

  return (
    <Container>
      {loaderData.data.attributes?.title && (
        <h1 style={{ marginBottom: 56 }}>
          {loaderData.data.attributes?.title}
        </h1>
      )}
      {bodyHtml && (
        <div
          style={{ marginBottom: 28 }}
          dangerouslySetInnerHTML={{ __html: bodyHtml }}
        />
      )}
      <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
        {imagesDivided?.map((row, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 16,
              height: isMobile ? "initial" : row.length === 1 ? 640 : 425,
            }}
          >
            {row.map((image, index) => (
              <img
                key={index}
                src={image.src}
                alt={image.alt}
                style={{
                  objectFit: "cover",
                  flexGrow: 1,
                }}
              />
            ))}
          </div>
        ))}
      </div>
    </Container>
  );
};

export const galleryRoute = rootRoute.createRoute({
  path: "galleries/$slug",
  component: Gallery,
  loader: galleryLoader,
});
