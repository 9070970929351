import { CSSProperties, ReactNode } from "react";
import globalStyles from "../lib/styles/global";

/**
 * A box that displays a message to the user.
 */
const Alert = ({
  children,
  style,
  type,
}: {
  style?: CSSProperties;
  children: ReactNode;
  type: "success" | "error" | "info" | "warning";
}) => {
  return (
    <div
      style={{
        backgroundColor: globalStyles.colors[type].background,
        color: globalStyles.colors[type].text,
        padding: 24,
        borderRadius: 8,
        border: `1px solid ${globalStyles.colors[type].text}`,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default Alert;
